<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }">
			<v-btn v-if="canSwitchDisplayMode" icon @click="switchDisplayMode()" v-on="on">
				<v-icon color="primary">{{ switchRepresentation.icon }}</v-icon>
			</v-btn>
		</template>
		<span>{{ switchRepresentation.text }}</span>
	</v-tooltip>
</template>

<script>
import { mapState } from 'vuex'

import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DisplayModeButton',
	mixins: [DocumentsManagerModuleGuard],
	data: function () {
		return {
			previousMode: null
		}
	},
	computed: {
		...mapState({
			isFiltering: state => state.documents.isFiltering
		}),
		displayMode: {
			get: function () {
				return this.$store.state.documents.displayMode
			},
			set: function (value) {
				this.$store.dispatch('documents/setDisplayMode', value)
			}
		},
		canSwitchDisplayMode: function () {
			return ['list', 'mosaic'].includes(this.displayMode)
		},
		switchRepresentation: function () {
			let result = {}
			switch (this.displayMode) {
				case 'list':
					result.icon = 'view_module'
					result.text = this.$t('documents.display_mode.mosaic')
					break
				case 'mosaic':
					result.icon = 'list'
					result.text = this.$t('documents.display_mode.list')
					break
			}
			return result
		}
	},
	watch: {
		isFiltering: {
			handler: function () {
				this.adaptMode()
			}
		}
	},
	created: function () {
		if (!this.displayMode) {
			this.$store.dispatch('documents/setDisplayMode', window.vueInstance?.$vuetify?.breakpoint.smAndDown && !this.conditionalDisplay.isMobileApp ? 'list' : 'mosaic')
		}
	},
	methods: {
		adaptMode: function () {
			if (this.isFiltering) {
				this.previousMode = this.displayMode
				this.displayMode = 'list'
			} else {
				this.displayMode = this.previousMode
			}
		},
		switchDisplayMode: function () {
			let newValue = null
			switch (this.displayMode) {
				case 'list':
					newValue = 'mosaic'
					break
				case 'mosaic':
					newValue = 'list'
					break
				default:
					newValue = 'mosaic'
					break
			}
			this.displayMode = newValue
		}
	}
}
</script>
